import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><iframe width={900} height={482} src="https://www.youtube.com/embed/pnlIuqEBn0U" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />

<MDXTag name="p" components={components}>{`Among the many treasures of the University of Michigan Special Collections Research Center is documentation of what was to have been Orson Welles’s first feature film--an adaptation of Joseph Conrad’s Heart of Darkness. Welles spent much of his first six months in Hollywood between July and December of 1939 hard at work on this project, which yielded a 182-page script containing hundreds of drawings that illustrate Welles’s ambitious concept for the film. Welles’s “The Heart of Darkness” was a timely antifascist parable he planned to narrate entirely in the first-person singular with himself playing the main character. In 2017, a previously unknown 314-page research bible Welles had commissioned for this unproduced project was rediscovered and acquired by the University of Michigan Special Collections Library. Entitled “General Research on ‘The Heart of Darkness,’” it helps better explain this aborted production and its political orientation in particular.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/welles.jpg","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`In their forthcoming book (`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.press.umich.edu"}}>{`University of Michigan Press`}</MDXTag>{`, under contract), co-authors Vincent Longo and Matthew Solomon contend that Welles’s outspoken 1930s anti-fascism crucially shaped “The Heart of Darkness” and how he planned to bring it to the screen. Through Fulcrum, the book will make digitally available all surviving traces of the film, including numerous pre-production documents, photographs, correspondence, and memoranda, which will together invite and support additional research on this famous unproduced film and its many contexts. This material was compiled and digitized by sixteen University of Michigan undergraduate student collaborators, who also used this rich array of archival material to create innovative digital projects—including original animations of Welles’s drawings, 3D/VR models, video databases, interactive maps—which visualize different aspects of “The Heart of Darkness.”`}</MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Heart of Darkness","key":"heart-of-darkness","sortOrder":0};

  